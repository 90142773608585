import React from 'react';
import html2canvas from 'html2canvas';
import Screenshot from './Screenshot';
import ReactDOM from 'react-dom';

function ScreenshotButton({ user, postText, comments }) {
  const takeScreenshot = async () => {
    try {
      // Create temporary container
      const screenshotContainer = document.createElement('div');
      document.body.appendChild(screenshotContainer);
      
      // Render the Screenshot component into the container
      ReactDOM.render(
        <Screenshot user={user} postText={postText} comments={comments} />,
        screenshotContainer
      );

      // Wait for any potential rendering
      await new Promise(resolve => setTimeout(resolve, 100));

      // Find the rendered component
      const element = screenshotContainer.firstChild;
      
      // Take screenshot
      const canvas = await html2canvas(element, {
        backgroundColor: '#ffffff',
        scale: 2,
        logging: false,
        useCORS: true
      });

      // Convert to blob and download
      canvas.toBlob((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'business-post.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 'image/png');

      // Cleanup
      document.body.removeChild(screenshotContainer);
    } catch (error) {
      console.error('Error taking screenshot:', error);
    }
  };

  return (
    <button 
      onClick={takeScreenshot}
      style={{
        backgroundColor: '#0b73da',
        color: 'white',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '16px',
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: '10px'
      }}
    >
      Download Screenshot
    </button>
  );
}

export default ScreenshotButton; 