import React from 'react';
import PostBox from './PostBox';
import ScreenshotComments from './ScreenshotComments';

function Screenshot({ user, postText, comments }) {
  return (
    <div style={{ 
      position: 'absolute', 
      left: '-9999px',
      backgroundColor: 'white',
      width: '550px',
      padding: '20px',
      borderRadius: '8px'
    }}>
      <PostBox user={user} postText={postText} />
      <ScreenshotComments comments={comments} />
      
      {/* Footer */}
      <div style={{
        borderTop: '1px solid #e0e0e0',
        paddingTop: '15px',
        textAlign: 'center',
        color: '#666',
        fontSize: '14px'
      }}>
        rydercalmdown.com/business-simulator
      </div>
    </div>
  );
}

export default Screenshot;