import React, { useState, useEffect } from 'react';

function SingleComment({ comment, isGenerating, delay, screenshot = false }) {
  const [isVisible, setIsVisible] = useState(false);

  // Show box with delay when generation starts
  useEffect(() => {
    if (!isGenerating || screenshot) return;

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [isGenerating, delay, screenshot]);

  const renderCommentText = (text) => {
    if (!text) return null;
    return text.split(' ').map((word, i) => {
      if (word.startsWith('#')) {
        return <span key={i} style={{ color: '#0b73da' }}>{word} </span>;
      }
      return word + ' ';
    });
  };

  // Screenshot mode
  if (screenshot && comment) {
    return (
      <div className="comment" style={{ opacity: 1 }}>
        <div className="comment-header">
          <div className="avatar small">
            {comment.author.split(' ').map(part => part[0]).join('').toUpperCase()}
          </div>
          <div>
            <div className="comment-author">{comment.author}</div>
            <div className="comment-title">{comment.title}</div>
          </div>
        </div>
        <div className="comment-text">{renderCommentText(comment.text)}</div>
      </div>
    );
  }

  // Don't show anything if not visible
  if (!isVisible && !screenshot) return null;

  // Show typing indicator if no comment data yet
  if (!comment) {
    return (
      <div className="comment" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="typing-animation">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  // Show complete comment when data arrives
  return (
    <div className="comment">
      <div className="comment-header">
        <div className="avatar small">
          {comment.author.split(' ').map(part => part[0]).join('').toUpperCase()}
        </div>
        <div>
          <div className="comment-author">{comment.author}</div>
          <div className="comment-title">{comment.title}</div>
        </div>
      </div>
      <div className="comment-text">{renderCommentText(comment.text)}</div>
    </div>
  );
}

export default SingleComment; 