import React, { useState } from 'react';
import './App.css';
import WelcomeScreen from './components/WelcomeScreen';
import ShareBox from './components/ShareBox';
import ResultsSection from './components/ResultsSection';
import Navbar from './components/Navbar';
import { getRandomComment } from './utils/commentGenerators';

function App() {
  const [userName, setUserName] = useState('');
  const [showNameInput, setShowNameInput] = useState(true);
  const [postText, setPostText] = useState('');
  const [isPosted, setIsPosted] = useState(false);
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [typingIndex, setTypingIndex] = useState(null);
  const [usedCommenters, setUsedCommenters] = useState(new Set());

  const handleNameSubmit = (e) => {
    e.preventDefault();
    if (userName.trim()) {
      setShowNameInput(false);
    }
  };

  const handlePost = async (content) => {
    if (!content || !content.trim()) return;
    
    const trimmedContent = content.trim();
    setPostText(trimmedContent);
    setIsPosted(true);
    setComments([]);
    setLoadingComments(true);
    setUsedCommenters(new Set());

    const generateComments = async () => {
      try {
        // First comment without flavor
        setTypingIndex(0);
        const standardComment = await getRandomComment(
          { author: userName, text: trimmedContent },
          false,
          usedCommenters,
          setUsedCommenters
        );
        
        if (standardComment) {
          setTimeout(() => {
            setComments([standardComment]);
          }, 0);
        }

        await new Promise(resolve => setTimeout(resolve, 1000));

        // 2-3 comments with random flavors
        const additionalComments = Math.floor(Math.random() * 2) + 2;
        
        for (let i = 0; i < additionalComments; i++) {
          setTypingIndex(i + 1);
          const comment = await getRandomComment(
            { author: userName, text: trimmedContent },
            true,
            usedCommenters,
            setUsedCommenters
          );
          
          if (comment) {
            setTimeout(() => {
              setComments(prev => [...prev, comment]);
            }, 0);
          }

          if (i < additionalComments - 1) {
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        setLoadingComments(false);
        setTypingIndex(null);
      }
    };

    // Start the comment generation process without awaiting it
    generateComments();
  };

  const handleReset = () => {
    setIsPosted(false);
    setPostText('');
    setComments([]);
    setLoadingComments(false);
    setTypingIndex(null);
    setUsedCommenters(new Set());
  };

  const user = {
    name: userName,
    profileImage: `https://ui-avatars.com/api/?name=${encodeURIComponent(userName)}&background=random`
  };

  return (
    <div>
      <Navbar />
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
        {showNameInput ? (
          <WelcomeScreen
            userName={userName}
            setUserName={setUserName}
            onSubmit={handleNameSubmit}
          />
        ) : !isPosted ? (
          <div className="container">
            <ShareBox user={user} onPost={handlePost} />
          </div>
        ) : (
          <ResultsSection
            user={user}
            postText={postText}
            comments={comments}
            typingIndex={typingIndex}
            loadingComments={loadingComments}
            onReset={handleReset}
          />
        )}
      </div>
    </div>
  );
}

export default App;
