import React from 'react';

function WelcomeScreen({ userName, setUserName, onSubmit }) {
  return (
    <div className="welcome-screen">
      <div className="welcome-content">
        <h1 className="welcome-title">Business Networking Website Simulator</h1>
        <p style={{ color: 'white', marginBottom: '40px', fontSize: '0.7em' }}>
          Don't worry, this whole simulator happens locally on your computer.
        </p>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            className="welcome-input"
            placeholder="Enter your full name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <button type="submit" className="welcome-button">
            Continue
          </button>
        </form>
        <p style={{ 
          color: '#fff', 
          fontSize: '0.6em', 
          marginTop: '40px',
          textAlign: 'center',
          maxWidth: '80%',
          margin: '40px auto 0'
        }}>
          This is a parody application created for entertainment purposes only. 
          It is not affiliated with, endorsed by, or connected to  any other social media platform or company. All content generated is fictional 
          and any resemblance to real persons or entities is purely coincidental.
        </p>
      </div>
    </div>
  );
}

export default WelcomeScreen;