import React from 'react';
import SingleComment from './SingleComment';

function Comments({ comments, typingIndex, screenshot = false }) {
  return (
    <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '50px'}}>
      {[0, 1, 2].map((index) => (
        <SingleComment
          key={index}
          comment={comments[index]}
          isGenerating={typingIndex !== null && index <= typingIndex}
          delay={index * 500}
          screenshot={screenshot}
        />
      ))}
    </div>
  );
}

export default Comments;