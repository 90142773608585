import React from 'react';

function ScreenshotComments({ comments }) {
  const renderCommentText = (text) => {
    return text.split(' ').map((word, i) => {
      if (word.startsWith('#')) {
        return <span key={i} style={{ color: '#0b73da' }}>{word} </span>;
      }
      return word + ' ';
    });
  };

  return (
    <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '20px' }}>
      {comments.map((comment, index) => (
        <div 
          key={index} 
          className="comment"
          style={{ 
            opacity: 1,
            animation: 'none',
            transition: 'none'
          }}
        >
          <div className="comment-header" style={{ opacity: 1 }}>
            <div className="avatar small" style={{ opacity: 1 }}>
              {comment.author.split(' ').map(part => part[0]).join('').toUpperCase()}
            </div>
            <div style={{ opacity: 1 }}>
              <div className="comment-author" style={{ opacity: 1 }}>{comment.author}</div>
              <div className="comment-title" style={{ opacity: 1 }}>{comment.title}</div>
            </div>
          </div>
          <div className="comment-text" style={{ opacity: 1 }}>
            {renderCommentText(comment.text)}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ScreenshotComments;

