import React from 'react';
import PostBox from './PostBox';
import Comments from './Comments';
import ScreenshotButton from './ScreenshotButton';

function ResultsSection({ user, postText, comments, typingIndex, loadingComments, onReset }) {
  return (
    <div className="container">
      <PostBox user={user} postText={postText} />
      <Comments comments={comments} typingIndex={typingIndex} />
      {!loadingComments && comments.length > 0 && (
        <div className="action-area" style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: '10px',
          paddingTop: '25px',
          borderTop: '1px solid #e0e0e0',
          paddingBottom: '50px',
        }}>
          <button className="write-another-button" onClick={onReset}>
            Write Another Post
          </button>
          <ScreenshotButton user={user} postText={postText} comments={comments} />
        </div>
      )}
      
    </div>
  );
}

export default ResultsSection; 