import React, { useState } from 'react';

function ShareBox({ user, onPost }) {
  const [content, setContent] = useState('');

  const suggestions = [
    {
      summary: "🎉 The Revolution",
      fullText: "🎉 Excited to announce I am quitting my job to raise an army of devious birds. The pigeons have chosen me as their leader. #BirdRevolution #WingedWarriors",
    },
    {
      summary: "🧘‍♂️ My New Achievement", 
      fullText: "🧘‍♂️ After 3 months of hard work and meditation, I am pleased to announce I can now communicate telepathically with office printers. #mindfulness #toner",
    },
    {
      summary: "🤖 Bold Leadership",
      fullText: "🤖 Proud to announce that as CEO I've made the bold decision to replace our entire 50,000-person workforce with ChatGPT. Productivity up 500%. #FutureOfWork #Visionary #Leadership",
    },
    {
      summary: "💪 Client Philosophy",
      fullText: "💪 Just published my whitepaper on the role of wrestling and bodyslams in modern business negotiations. Sometimes you just have to put that difficult client in a headlock. #MoveFastAndBreakThings #HustleCulture",
    },
    {
      summary: "🌟 CEO Discovery",
      fullText: "🌟 Breaking: I've discovered that my houseplant is actually a startup CEO in disguise. Series A funding announcement coming soon! #PlantPreneurs #GreenInnovation"
    },
    {
      summary: "🥓 Hiring Win",
      fullText: "🥓 Just hired a guy who showed up 45 minutes late to his interview because he was 'in the middle of a critical brisket situation.' When asked why we should hire him, he pulled out photos of his award-winning BBQ and said 'I bring this level of dedication to everything I do.' Now he brings in smoked meats every week and calls it 'team building.' The office smells amazing and productivity is up 300%. Best hiring decision ever. #RecruitingWin #BBQBoss #SmokingMeatLife #UnorthodoxSuccess"
    },
    {
      summary: "💡 Unpaid Internships",
      fullText: "💡 We don't like unpaid internships. That's why we took a different strategy. We pay our interns a stipend of 1000 dollars a month for the 4 months they are with us. For the opportunity of getting to work with us, we ask them to take on a small debt of 60,000 dollars that they can pay back over the course of their time with us. THOUGHTS? #leadership #visionary"
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content.trim()) {
      onPost(content);
      setContent('');
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setContent(suggestion.fullText);
  };

  return (
    <div className="post-box">
      <div className="user-info">
        <div className="avatar">{user.name.split(' ').map(part => part[0]).join('').toUpperCase()}</div>
        <div className="user-name">{user.name}</div>
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
          className="input-area"
          placeholder="What do you want to talk about?"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <button type="submit" className="post-button">
          Post
        </button>
      </form>
      <div className="suggestions-container" style={{ color: '#666', fontSize: '0.9em' }}>
        <h4>Need inspiration? Try one of these:</h4>
        <div className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              className="suggestion-button"
              onClick={() => handleSuggestionClick(suggestion)}
              style={{ 
                border: 'none',
                background: 'none',
                color: '#666',
                fontSize: '0.9em',
                padding: '5px',
                cursor: 'pointer',
                textAlign: 'left'
              }}
            >
              {suggestion.summary}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShareBox;