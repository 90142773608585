import React, { useState, useEffect } from 'react';

function PostBox({ user, postText }) {
  const [reactions, setReactions] = useState({
    like: 0,
    celebrate: 0,
    love: 0
  });

  const renderPostText = (text) => {
    return text.split(' ').map((word, i) => {
      if (word.startsWith('#')) {
        return <span key={i} style={{ color: '#0b73da' }}>{word} </span>;
      }
      return word + ' ';
    });
  };

  useEffect(() => {
    const targetReactions = {
      like: Math.floor(Math.random() * 18) + 2,
      celebrate: Math.floor(Math.random() * 6) + 2, 
      love: Math.floor(Math.random() * 8) + 2
    };

    const duration = 8000; // 8 seconds
    const steps = 50; // Increase number of steps for smoother animation
    const interval = duration / steps;

    const incrementReactions = (step) => {
      requestAnimationFrame(() => {
        setReactions({
          like: Math.floor((targetReactions.like / steps) * step),
          celebrate: Math.floor((targetReactions.celebrate / steps) * step),
          love: Math.floor((targetReactions.love / steps) * step)
        });
      });
    };

    let currentStep = 0;
    const timer = setInterval(() => {
      currentStep++;
      if (currentStep <= steps) {
        incrementReactions(currentStep);
      } else {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer);
  }, [postText]); // Add postText as dependency to reset animation when post changes

  return (
    <div className="post-box">
      <div className="user-info">
        <div className="avatar">
          {user.name.split(' ').map(part => part[0]).join('').toUpperCase()}
        </div>
        <div className="user-name">{user.name}</div>
      </div>
      <div className="post-text">{renderPostText(postText)}</div>
      <div className="reactions-bar">
        <div className="reactions-list" style={{display: 'flex', flexDirection: 'row'}}>
          <div className="reaction-tab" style={{padding: '10px 0px', display: 'flex', alignItems: 'center', gap: '2px', color: '#666', fontSize: '0.9em'}}>
            <div style={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: '#0b73da',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '10px'
            }}>
              👍
            </div>
            <span>{reactions.like}</span>
          </div>
          <div className="reaction-tab" style={{padding: '4px 10px', display: 'flex', alignItems: 'center', gap: '2px', color: '#666', fontSize: '0.9em'}}>
            <div style={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: '#57b95b',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '10px'
            }}>
              🎉
            </div>
            <span>{reactions.celebrate}</span>
          </div>
          <div className="reaction-tab" style={{padding: '4px 2px', display: 'flex', alignItems: 'center', gap: '2px', color: '#666', fontSize: '0.9em'}}>
            <div style={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: '#e33e5a',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '10px'
            }}>
              ❤️
            </div>
            <span>{reactions.love}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostBox;