import React from 'react';

function Navbar() {
  return (
    <nav style={{
      backgroundColor: '#0b73da',
      padding: '12px 24px',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
      <a href="/" style={{
        color: 'white',
        textDecoration: 'none',
        fontSize: '1.2em',
        fontWeight: 'bold'
      }}>
        Business Networking Website Simulator
      </a>
      <a href="https://rydercalmdown.com" 
         target="_blank" 
         rel="noopener noreferrer"
         style={{
           color: 'white',
           textDecoration: 'none',
           fontSize: '0.9em'
         }}>
        A RyderCalmDown project
      </a>
    </nav>
  );
}

export default Navbar; 