export const commenters = [
    { name: "Gary Thompson", title: "Thought Leader | Innovation Catalyst | Future-Forward Strategist" },
    { name: "Sarah Mitchell", title: "Chief Dream Officer | Professional Game-Changer | Happiness Engineer" },
    { name: "David Kumar", title: "Digital Nomad | Growth Hacker | Professional People Person" },
    { name: "Lisa Wang", title: "Vision Architect | Mindset Alchemist | Serial Entrepreneur" },
    { name: "Michael Roberts", title: "Impact Maven | Culture Curator | Cat Influencer" },
    { name: "Rachel Chen", title: "Paradigm Shifter | Revenue Ninja | Empowerment Coach" },
    { name: "James Wilson", title: "Disruption Expert | Change Agent | Synergy Architect" },
    { name: "Emily Zhang", title: "Experience Designer | Brand Storyteller | Innovation Sherpa" },
    { name: "Marcus Johnson", title: "Growth Mindset Guru | People Catalyst | Success Engineer" },
    { name: "Priya Patel", title: "Digital Transformer | Engagement Wizard | Solution Sculptor" },
    { name: "Alex Rivera", title: "Future Forecaster | Culture Hacker | Possibility Pioneer" },
    { name: "Jennifer Kim", title: "Impact Accelerator | Joy Creator | Transformation Guide" },
    { name: "Christopher Lee", title: "Innovation Architect | Mindfulness Maven | Strategy Sage" },
    { name: "Sophia Anderson", title: "Vision Weaver | Experience Enchanter | Growth Guru" },
    { name: "Ryan Martinez", title: "Paradigm Pioneer | Engagement Expert | Culture Champion" },
    { name: "Michelle Wong", title: "Dream Director | Change Catalyst | Success Strategist" },
    { name: "Daniel Brown", title: "Thought Architect | Innovation Scout | Future Shaper" },
    { name: "Amanda Taylor", title: "Brand Alchemist | Connection Curator | Impact Innovator" },
    { name: "Kevin O'Brien", title: "Strategy Savant | Transformation Trainer | Wisdom Walker" },
    { name: "Nicole Chang", title: "Experience Explorer | Potential Unleasher | Joy Engineer" },
    { name: "Thomas Wright", title: "Culture Composer | Innovation Igniter | Dream Designer" },
    { name: "Jessica Nguyen", title: "Vision Vector | Momentum Maker | Growth Guardian" },
    { name: "Robert Kim", title: "Future Facilitator | Impact Illuminator | Change Champion" },
    { name: "Maria Garcia", title: "Success Sculptor | Innovation Oracle | Happiness Hacker" },
    { name: "Steven Chen", title: "Mindset Maestro | Experience Evangelist | Progress Pilot" },
    { name: "Laura Williams", title: "Dream Deployer | Culture Conductor | Vision Virtuoso" }
];

export const getRandomComment = async (content, useRandomFlavor = false, usedIndices, setUsedIndices) => {
    let availableIndices = Array.from(Array(commenters.length).keys())
        .filter(i => !usedIndices.has(i));
    
    if (availableIndices.length === 0) {
        setUsedIndices(new Set());
        availableIndices = Array.from(Array(commenters.length).keys());
    }

    const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
    const selectedCommenter = commenters[randomIndex];
    
    setUsedIndices(new Set([...usedIndices, randomIndex]));
    
    const flavors = ['advocate', 'brag', 'corporate', 'motivational', 'sales', 'storyteller'];
    
    const requestBody = {
        author: content.author,
        text: content.text
    };

    if (useRandomFlavor) {
        requestBody.flavor = flavors[Math.floor(Math.random() * flavors.length)];
    }
    
    try {
        const response = await fetch('https://us-east1-radical-sloth.cloudfunctions.net/business_networking_simulator_api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch comment: ${await response.text()}`);
        }

        const data = await response.json();
        
        // Strip quotation marks if they exist at start and end of response
        const cleanedResponse = data.response.replace(/^"|"$/g, '');
        
        return {
            author: selectedCommenter.name,
            text: cleanedResponse,
            title: selectedCommenter.title
        };
    } catch (error) {
        console.error('Error fetching comment:', error);
        throw error;
    }
}; 